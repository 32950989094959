<template>
  <ul class="menu-nav">
    <router-link
      custom
      :to="{ name: 'dashboard' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        style="margin-top: auto"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="media/svg/icons/Design/Layers.svg" />
          </span>

          <span class="menu-text">{{ $t("MENU.DASHBOARD") }}</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.WORKERS_AND_CONSTRUCTIONS") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/workers'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Communication/Group.svg" />
        </span>
        <span class="menu-text">{{ $t("MENU.WORKERS") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t("MENU.WORKERS") }}</span>
            </span>
          </li>
          <router-link
            custom
            :to="{ name: 'workers-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("MENU.ALL_WORKERS") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            :to="{ name: 'worker-new' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("GENERAL.ADD_NEW") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/constructions'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Tools/Hummer.svg" />
        </span>
        <span class="menu-text">{{ $t("MENU.CONSTRUCTIONS") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t("MENU.CONSTRUCTIONS") }}</span>
            </span>
          </li>

          <router-link
            custom
            :to="{ name: 'constructions-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">
                  {{ $t("MENU.ALL_CONSTRUCTIONS") }}
                </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            :to="{ name: 'construction-new' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("GENERAL.ADD_NEW") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section mt-0">
      <h4 class="menu-text">{{ $t("MENU.FINANCE") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/hourly-rates'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Home/Clock.svg" />
        </span>
        <span class="menu-text">{{ $t("MENU.HOURLY_RATE") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t("MENU.HOURLY_RATE") }}</span>
            </span>
          </li>

          <router-link
            custom
            :to="{ name: 'hourly-rates-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("MENU.ALL_HOURLY_RATES") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            custom
            :to="{ name: 'hourly-rate-new' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("GENERAL.ADD_NEW") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/cost-sheets'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Calculator.svg" />
        </span>
        <span class="menu-text">{{ $t("MENU.COST_SHEET") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t("MENU.COST_SHEET") }}</span>
            </span>
          </li>

          <router-link
            v-if="currentUser.roles[0] !== 'ROLE_BABY_ADMIN'"
            custom
            :to="{ name: 'cost-sheets-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("MENU.ALL_COST_SHEETS") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            custom
            :to="{ name: 'cost-sheet-new' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("GENERAL.ADD_NEW") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="currentUser.roles[0] !== 'ROLE_BABY_ADMIN'"
            custom
            :to="{ name: 'cost-sheets-total' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("MENU.TOTAL") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section mt-0">
      <h4 class="menu-text">Notification</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/notifications'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Home/Clock.svg" />
        </span>
        <span class="menu-text">Notifications</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Notifications</span>
            </span>
          </li>

          <router-link
            custom
            :to="{ name: 'notifications-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">All Notifications</span>
              </a>
            </li>
          </router-link>

          <router-link
            custom
            :to="{ name: 'notification-new' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Add New</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li v-if="currentUser.roles[0] === 'ROLE_SUPER_ADMIN'" class="menu-section">
      <h4 class="menu-text">Super Admin Area</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      v-if="currentUser.roles[0] === 'ROLE_SUPER_ADMIN'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/users'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
        </span>
        <span class="menu-text">{{ $t("MENU.USERS") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ $t("MENU.USERS") }}</span>
            </span>
          </li>
          <router-link
            custom
            :to="{ name: 'users-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("MENU.ALL_USERS") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            :to="{ name: 'users-new' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("GENERAL.ADD_NEW") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
